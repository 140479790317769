.sign-in {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .sign-in-button {
    background-color: rgba(211, 211, 211, 0.8); /* Light gray with 80% opacity */
    color: black;
    border: none;
    border-radius: 12px; /* Rounded corners */
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .sign-in-button:hover {
    background-color: rgba(208, 208, 208, 0.8); /* Slightly darker gray with 80% opacity on hover */
  }
  
  .sign-in-button img {
    width: 20px;
    height: 20px;
  }
  
  .sign-in-button:hover {
    background-color: #d0d0d0;
  }

  .policy-links {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
  
  .policy-links a {
    color: #D3D3D3; /* Set the color to light gray */
    text-decoration: none;
    margin: 0 1rem;
  }
  
  .policy-links a:hover {
    text-decoration: underline;
  }