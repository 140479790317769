.container {
    display: flex;
    justify-content: space-between;
    margin: 5pt 0pt
}

.user-container {
    display: flex;
    justify-content: start;
    gap: 10pt;
}