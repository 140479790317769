.main-page {
    background-color: black; /* Fill the background with black */
    display: flex; /* Use Flexbox to center the image */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    height: 100vh; /* Fill the entire viewport height */
    width: 100%; /* Fill the entire viewport width */
  }
  
  .main-page img {
    max-width: 80%; /* Prevent the image from being too large */
    max-height: 80%; /* Prevent the image from being too tall */
  }  